import styles from "./index.module.scss";
import Card from "./card";
import CardImg1 from "./assets/Frame1.svg";
import CardImg2 from "./assets/Frame2.svg";
import CardImg3 from "./assets/Frame3.svg";
import Python1 from "./assets/python1.svg";
import Python2 from "./assets/python2.svg";
import Python3 from "./assets/python3.svg";

const Section = () => {
  const cards = [
    {
      type: "Python 小白入门课",
      icon: <Python1 />,
      title: "什么是 Python",
      description:
        "Python 是一种流行的、易学的、非常强大的编程语言，常用于 web 开发、数据科学、机器学习。",
      image: <CardImg1 />,
      bgColor: "#F2F3FF",
      path: "/learn#/course/015/catalog",
    },
    {
      type: "Python 小白数据分析",
      icon: <Python2 />,
      title: "为什么是 Python",
      description:
        "Python 在数据科学中广泛使用，它有一套强大的工具探索及可视化数据。",
      image: <CardImg2 />,
      bgColor: "#FFFAF2",
      path: "learn#/course/017/catalog",
    },
    {
      type: "Python 小白数据结构",
      icon: <Python3 />,
      title: "成为 Python 高手",
      description:
        "数据结构编程的基石，通过 Python 的数据结构可完成更多有趣复杂的实战任务！",
      image: <CardImg3 />,
      bgColor: "#FDF7F5",
      path: "/learn#/course/018/catalog",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>完美适配系列课程</div>
        <div className={styles.cards}>
          {cards.map((card) => (
            <Card
              icon={card.icon}
              type={card.type}
              title={card.title}
              link={card.path}
              description={card.description}
              image={card.image}
              bgColor={card.bgColor}
              key={card.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
