import styles from "./index.module.scss";
import Card from "../card";
import CardImg1 from "./assets/4-1.svg";
import CardImg2 from "./assets/4-2.svg";
import CardImg3 from "./assets/4-3.svg";

const Section = () => {
  const cards = [
    {
      title: "受欢迎",
      description:
        "编程是高增长需求，全球超过60%的新工作需要编程技能，解法双手。",
      image: <CardImg1 />,
    },
    {
      title: "有前途",
      description:
        "开启你的收入潜力! 入门级的程序员在平均收入超过 2w rmb 的薪水。",
      image: <CardImg2 />,
    },
    {
      title: "很有趣",
      description: "将你的激情和创造力通过编程来完美释放吧，让每天都充满想象。",
      image: <CardImg3 />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>学习编程的理由</div>
        <div className={styles.cards}>
          {cards.map((card) => (
            <Card
              title={card.title}
              description={card.description}
              image={card.image}
              key={card.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
