import axios from "axios";
import {
  updateAvatar,
  updateSegments,
  updateNickname,
} from "@quark/customer-service";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "//edu.py2fun.com/";
axios.interceptors.response.use((response) => {
  const { code, data, msg } = response.data;
  if (code === 0) {
    return data;
  }
  return Promise.reject(msg);
});

type UserInfo = {
  avatar: string;
  is_login: boolean;
  is_wxbind: boolean;
  nickname: string;
};

export const getUserInfo = async (): Promise<UserInfo> => {
  const userInfo = await axios.get<any, UserInfo>(
    "//api.py2fun.com/api/pytip/wechat_is_login"
  );
  if (userInfo.is_login) {
    updateAvatar(userInfo.avatar);
    updateNickname(userInfo.nickname);
    updateSegments(userInfo);
  }
  return userInfo;
};
