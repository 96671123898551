import React, { FC, ReactNode } from "react";
import styles from "./index.module.scss";

interface CardProps {
  title: string;
  description: string;
  image: ReactNode;
  width?: number | string;
}

const Card: FC<CardProps> = ({ title, description, image, width = 420 }) => {
  return (
    <div className={styles.card} style={{ width }}>
      <div className={styles.image}>{image}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default Card;
