import styles from "./index.module.scss";
import People from "./assets/people.svg";
import Button from "../button";
import { LEARN_BTN_CLICK_EVENT } from "../../constants/event";

const Section = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>学习编程的好方法</div>
          <div className={styles["sub-title"]}>
            <div>
              面向真实场景，边学边练，零基础无门槛，覆盖 Python 小白到进阶课程。
            </div>
            <div></div>
            <div>
              轻松、有趣、好玩，
              <span className={styles.hl}>交互式</span>
              学习，
              <span className={styles.hl}>1 v 1</span>
              &nbsp;及
              <span className={styles.hl}> 社区 </span>
              答疑。
            </div>
          </div>
          <Button
            type="light"
            onClick={() => {
              window.gtag("event", LEARN_BTN_CLICK_EVENT, { from: "section" });
              window.location.href = "/learn#/course/015/catalog";
            }}
          >
            现在开始学习
          </Button>
        </div>
        <People />
      </div>
    </div>
  );
};

export default Section;
