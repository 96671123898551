import React, { FC, ReactNode } from "react";
import styles from "./index.module.scss";

interface CardProps {
  type: string;
  title: string;
  description: string;
  link: string;
  icon: ReactNode;
  image: ReactNode;
  bgColor: string;
  width?: number | string;
}

const Card: FC<CardProps> = ({
  type,
  icon,
  title,
  link,
  description,
  image,
  bgColor,
  width = 420,
}) => {
  const jumpTo = (path) => {
    window.location.href = path;
  };

  return (
    <div
      className={styles.card}
      onClick={() => jumpTo(link)}
      style={{ width, backgroundColor: bgColor }}
    >
      <div className={styles.header}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.type}>{type}</div>
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.image}>{image}</div>
    </div>
  );
};

export default Card;
