import Head from "next/head";
import Script from "next/script";
import Header from "../components/header";
import Footer from "../components/footer";
import Section0 from "../components/section0";
import Section1 from "../components/section1";
import Section2 from "../components/section2";
import Section3 from "../components/section3";
import Section4 from "../components/section4";
import Section5 from "../components/section5";

import { getUserInfo } from "../api";
import React, { useEffect, useMemo } from "react";
import { useCrisp } from "@quark/customer-service";

function IndexPage() {
  useCrisp();

  useEffect(() => {
    getUserInfo().then((userInfo) => {
      if (userInfo.is_login) {
        window.location.href = "/learn";
      }
    });
  }, []);

  const isProd = useMemo(() => {
    if (typeof window !== "undefined") {
      const { hostname } = (window as any).location;
      return hostname === "edu.py2fun.com";
    }
    return false;
  }, []);

  return (
    <>
      <Head>
        <meta name="robots" content="all" />
        <meta name="author" content="pythontip.com" />
        <meta
          name="keywords"
          content="哈希编程 Python学习 python基础教程 python培训 编程入门 数据结构与算法 数据分析 小白学编程 30天入门 在线学编程 编程题库 爬虫"
        />
        <meta
          name="description"
          content="哈希编程，中文 Python 学习网站，学习编程的好方法，面向真实场景，边学边练，零基础无门槛，覆盖 Python 小白到进阶课程。轻松、有趣、好玩，交互式学习，1 v 1 及 社区 答疑。"
        />
        <link rel="icon" href="/favicon.ico" />
        <title>
          哈希编程 - PythonTip学编程 - 从小白到高手，学习 python 从未如此简单
        </title>
      </Head>
      <Header />
      <Section0 />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />

      <>
        {isProd && (
          <Script
            id="clarity-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "dwjipfkks8");
          `,
            }}
          />
        )}
        {isProd && (
          <Script
            id="baidu"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?9090e2a04fdf488a20e3e56817469ac4";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
          `,
            }}
          />
        )}
        {isProd ? (
          <>
            <Script
              src="https://www.googletagmanager.com/gtag/js?id=G-X49D9KV3YC"
              strategy="afterInteractive"
            />
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-X49D9KV3YC');
              `,
              }}
            />
          </>
        ) : (
          <>
            <Script
              src="https://www.googletagmanager.com/gtag/js?id=G-F85W00M8H5"
              strategy="afterInteractive"
            />
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-F85W00M8H5');
              `,
              }}
            />
            <Script
              async
              src="https://ackee.py2fun.com/tracker.js"
              data-ackee-server="https://ackee.py2fun.com"
              data-ackee-domain-id="56104d6a-c341-4cf0-9fef-51597e7cb65a"
              data-ackee-opts='{ "detailed": true }'
            />
          </>
        )}
      </>
    </>
  );
}

export default IndexPage;
