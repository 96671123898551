import styles from "./index.module.scss";
import Card from "../card";
import CardImg1 from "./assets/card1.svg";
import CardImg2 from "./assets/card2.svg";
import CardImg3 from "./assets/card3.svg";

const Section = () => {
  const cards = [
    {
      title: "适应性",
      description:
        "无论您的经验水平如何，您将在开始第一门课程的几分钟内编写真正的、功能性的代码。",
      image: <CardImg1 />,
    },
    {
      title: "一步步",
      description:
        "一步一步地通过我们独特的课程。通过课堂测试来评估你所学的内容，并通过练习逐步提高你的技能。",
      image: <CardImg2 />,
    },
    {
      title: "有证书",
      description:
        "获得证书来验证你新获得的技能。把它发布在社交网络上，让别人看到。",
      image: <CardImg3 />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {cards.map((card) => (
          <Card
            title={card.title}
            description={card.description}
            image={card.image}
            key={card.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Section;
