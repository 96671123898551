import Button from "../button";
import styles from "./index.module.scss";
import { BeiAn } from "@quark/design";
import { LEARN_BTN_CLICK_EVENT } from "../../constants/event";
import { useKV } from "@quark/kits";

const Footer = () => {
  const { data: mpQrcode } = useKV("hash-mp");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>编程从未如此简单</div>
          <Button
            onClick={() => {
              window.gtag("event", LEARN_BTN_CLICK_EVENT, { from: "footer" });
              window.location.href = "/learn#/course/015/catalog";
            }}
          >
            开始我的编程生涯
          </Button>
        </div>
        <div className={styles["bottom-bar"]}>
          <img src={mpQrcode?.value} alt="" />
          关注公众号
          <BeiAn className={styles.beian} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
