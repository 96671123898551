import { useState as a, useEffect as c } from "react";
import { Crisp as o } from "crisp-sdk-web";
import { Crisp as N } from "crisp-sdk-web";
import { nanoid as d } from "nanoid";
import m from "dayjs";
const h = () => !!window.navigator.userAgent.match(/MicroMessenger/i), g = () => {
  let e = window.localStorage.getItem("CRISP_TOKEN_ID");
  return e || (e = d(), window.localStorage.setItem("CRISP_TOKEN_ID", e)), e;
}, l = async () => {
  const s = await (await fetch("https://edu.py2fun.com/api/user/profile")).json(), { code: n, data: r } = s;
  if (n === 0)
    return r;
}, I = (e) => {
  e !== "同学" && o.user.setNickname(e);
}, w = (e) => {
  const {
    is_login: s,
    has_course: n,
    vip_infos: r = [],
    learn_day: i,
    nickname: u,
    register_time: f
  } = e, t = [];
  if (s && t.push("已登录"), h() ? t.push("移动端") : t.push("PC端"), n && t.push("已购课"), r.length > 0 && t.push("会员"), f) {
    const p = m(/* @__PURE__ */ new Date()).diff(f, "day");
    t.push(`已注册${p}天`);
  }
  t.push(u), t.push(`学习${i}天`), o.session.setSegments(t, !0);
}, _ = (e, s) => {
  e || (e = `https://api.dicebear.com/6.x/bottts/png?seed=${s}`), e.startsWith("//") && (e = `https:${e}`), o.user.setAvatar(e);
}, C = () => {
  const [e, s] = a();
  c(() => {
    l().then(s);
  }, []), c(() => {
    if (!e)
      return;
    const { nickname: n, avatar: r, openid: i } = e;
    o.configure("f8124570-a7ff-4c07-bf9d-bb6f6215d3d5", {
      safeMode: !0,
      sessionMerge: !0
    }), o.setTokenId(g()), _(r, i), w(e), I(n), o.load(), o.setHideOnMobile(!0);
  }, [e]);
};
export {
  N as Crisp,
  _ as updateAvatar,
  I as updateNickname,
  w as updateSegments,
  C as useCrisp
};
