import styles from "./index.module.scss";
import Card from "../card";
import CardImg1 from "./assets/3-1.svg";
import CardImg2 from "./assets/3-2.svg";

const Section = () => {
  const cards = [
    {
      title: "学生",
      description:
        "是在准备大考还是想在第一次面试中取得好成绩?利用 Pythontip 的现实实践来巩固你所学到的知识，并为那个重要的时刻做好准备。",
      image: <CardImg1 />,
    },
    {
      title: "专业人员",
      description:
        "你可以学到一些全新的东西来促进你的职业发展。或者你只是想把铁锈去掉。尝试 Pythontip 来获得各种各样的课程，从机器学习到网络开发。",
      image: <CardImg2 />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>提升编程技能的完美平台</div>

        <div className={styles.cards}>
          {cards.map((card) => (
            <Card
              title={card.title}
              description={card.description}
              image={card.image}
              key={card.title}
              width={520}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
