import cn from "classnames";
import styles from "./index.module.scss";
import React, { FC } from "react";

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: "dark" | "light";
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  type = "dark",
  ...otherProps
}) => {
  return (
    <div className={cn(styles.button, className, styles[type])} {...otherProps}>
      {children}
    </div>
  );
};

export default Button;
