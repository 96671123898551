import styles from "./index.module.scss";
import LeftImg from "./assets/5-1.svg";
import Button from "../button";
import { Modal } from "@quark/design";
import { useState } from "react";
import { JOIN_BTN_CLICK_EVENT } from "../../constants/event";
import { useKV } from "@quark/kits";

const Section = () => {
  const [qrCodeVisible, setQRCodeVisible] = useState(false);
  const { data: teacherQrcode } = useKV("teacher");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>社区及答疑</div>
        <div className={styles.cards}>
          <LeftImg />
          <div className={styles.right}>
            <div className={styles["right-title"]}>社区已加入人数</div>
            <div className={styles["right-number"]}>2,000,000</div>
            <div className={styles["right-desc"]}>
              分享您的代码或问题，并获得即时反馈或成为一个创造者，并传播您的知识和经验，我们的全球社区的学习者。
            </div>
            <Button
              onClick={() => {
                window.gtag("event", JOIN_BTN_CLICK_EVENT, { from: "section" });
                setQRCodeVisible(true);
              }}
            >
              加入社区
            </Button>
          </div>
        </div>
      </div>

      <Modal
        visible={qrCodeVisible}
        title="加入社区"
        portal={false}
        onClose={() => {
          setQRCodeVisible(false);
        }}
      >
        <div>
          <img src={teacherQrcode?.value} alt="" />
        </div>
      </Modal>
    </div>
  );
};

export default Section;
