import { useState as a, useEffect as i } from "react";
import g, { AxiosError as u } from "axios";
const c = g.create({
  baseURL: "//edu.py2fun.com/",
  withCredentials: !0
});
c.interceptors.response.use((t) => {
  const { status: n } = t;
  if (n < 200 && n >= 300) {
    const { msg: o } = t.data, e = new u();
    throw e.code = n.toString(), e.response = t, e.config = t.config, e.request = t.request, e.message = o, e;
  }
  return t;
});
c.interceptors.response.use(
  (t) => {
    const { code: n, data: o, msg: e } = t.data;
    if (n === 0)
      return o;
    const s = new u();
    throw s.code = n.toString(), s.response = t, s.config = t.config, s.request = t.request, s.message = e, t.status === 401 && (s.message = e ?? "请先登录"), s;
  },
  (t) => {
    if (t.response)
      switch (t.response.status) {
        case 401:
          return window.dispatchEvent(new Event("need-login")), Promise.reject("需要登录");
      }
    return Promise.reject(t);
  }
);
const f = (t) => c.get("/api/kvcfg/get", { params: { key: t } }), l = (t) => c.get("/api/kvcfg/mult_get", { params: { keys: t.join(",") } }), h = (t) => {
  const [n, o] = a(null), [e, s] = a(!1);
  return i(() => {
    e || (s(!0), f(t).then((r) => {
      o(r);
    }).catch((r) => {
      console.log(r);
    }).finally(() => {
      s(!1);
    }));
  }, []), { data: n, loading: e };
}, p = (t) => {
  const [n, o] = a({}), [e, s] = a(!1);
  return i(() => {
    e || (s(!0), l(t).then((r) => {
      o(r);
    }).catch((r) => {
      console.log(r);
    }).finally(() => {
      s(!1);
    }));
  }, []), { data: n, loading: e };
};
export {
  h as useKV,
  p as useKVs
};
