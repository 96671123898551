import styles from "./index.module.scss";
import Logo from "../../assets/logo.svg";

const menus = [
  {
    key: "course",
    title: "课程",
    path: "/learn#/course/list",
  },
  {
    key: "problemset",
    title: "题库",
    path: "/learn#/problemset/all/1",
  },
  {
    key: "blog",
    title: "专栏",
    path: "/learn#/blog/list",
  },
  {
    key: "online-editor",
    title: "在线编程",
    path: "/learn#/playground",
  },
  {
    key: "exam",
    title: "二级刷题",
    path: "/learn#/exam/list",
  },
  {
    key: "app-download",
    title: "APP下载",
    path: "/learn#/app-download",
  },
  {
    key: "vip",
    title: <div className={styles.vipTitle}>VIP会员</div>,
    path: "/learn#/vip",
  },
];

const Header = () => {
  const jumpTo = (path: string) => {
    if (!path) {
      alert("未定义跳转路径");
      return;
    }
    if (path.includes("http")) {
      window.open(path, "_blank");
      return;
    }
    window.location.href = path;
  };

  const login = () => {
    const redirect = encodeURIComponent("https://edu.py2fun.com/learn");
    window.location.href = `//edu.py2fun.com/login?redirect_url=${redirect}`;
  };

  return (
    <nav className={styles.header}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.logo} onClick={() => jumpTo("/learn#/user")}>
            <Logo />
          </div>
          <div className={styles.menus}>
            {menus.map(({ title, path, key }) => (
              <a className={styles.menu} key={key} href={path}>
                {title}
              </a>
            ))}
          </div>
        </div>

        <div className={styles.login} onClick={() => login()}>
          登陆
        </div>
      </div>
    </nav>
  );
};

export default Header;
